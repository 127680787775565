import React, { Fragment } from 'react';
import classnames from 'classnames';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Translation from '../../components/Text/Translation';
import css from './FlyoutHandle.scss';
import { useScreenSize } from '../../hooks/useScreenSize';

export default function FlyoutHandle(props) {
  const {
    showComments,
    toggleCommentPane,
    commentCount,
    privateCount,
    navigateComments,
    showUpArrow,
    showDownArrow,
  } = props;

  const { isSmallScreen } = useScreenSize();

  return (
    <Fragment>
      {showComments && showUpArrow && (
        <CommentNavigationArrow direction="up" navigateComments={navigateComments} />
      )}
      <div
        className={classnames(css.Flyout_Handle, {
          [css['Flyout_Handle--open']]: showComments,
        })}
        tabIndex={0}
        role="button"
        onClick={(event) => {
          event.preventDefault();
          toggleCommentPane();
        }}
      >
        <Heading
          showComments={showComments}
          displayedCommentCount={commentCount - privateCount}
        />
        <SubHeading
          showComments={showComments}
          isSmallScreen={isSmallScreen}
          commentCount={commentCount}
          privateCount={privateCount}
        />
      </div>
      {showComments && showDownArrow && (
        <CommentNavigationArrow direction="down" navigateComments={navigateComments} />
      )}
    </Fragment>
  );
}

function Heading({showComments, displayedCommentCount}) {
  return (
    <div
      className={classnames(css.Flyout_Handle__heading, {
        [css['Flyout_Handle__heading--open']]: showComments,
      })}
    >
      {showComments ? '×' : displayedCommentCount}
    </div>
  );
}

function SubHeading({showComments, isSmallScreen, commentCount, privateCount}) {
  return (
    <Fragment>
      {!isSmallScreen &&
        <div className={css.Flyout_Handle__subheading}>
          {showComments
            ? <Translation
              value="flyout.comments.close"
              naked
            />
            : <Translation
              value={commentCount - privateCount > 1 ? 'flyout.comments.heading.plural' : 'flyout.comments.heading.single'}
              naked
            />
          }
        </div>
      }
      {!showComments && !!privateCount && (
        <div className={css.Flyout_Handle__private}>
          {`+ ${privateCount} `}
          {isSmallScreen
            ? (
              <InlineSVG
                className={css.Flyout_Handle__private__icon}
                src="img/icons/material/visibility_off_flipped-24px.svg"
              />
            ) : (
              <Translation
                value="flyout.comments.private"
                naked
              />
            )}
        </div>
      )}
    </Fragment>
  );
}

function CommentNavigationArrow({direction, navigateComments}) {
  return (
    <button
      type="button"
      onClick={() => navigateComments(direction)}
      className={classnames(css.Flyout_Handle__arrow, css[`Flyout_Handle__arrow__${direction}`])}
    >
      <InlineSVG
        className={css.Flyout_Handle__arrow__icon}
        src={direction === 'up' ? 'img/interface/arrow-upward.svg' : 'img/interface/arrow-downward.svg'}
      />
    </button>
  );
}
