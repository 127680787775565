import React, { Fragment, useCallback, useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import css from './CommentsFlyout.scss';
import ResizeHandle from './ResizeHandle';
import { useLocalStorage } from '../../components/LocalStorage';
import { useScreenSize } from '../../hooks/useScreenSize';
import FlyoutHandle from './FlyoutHandle';
import Translation from '../../components/Text/Translation';
import IconButton from '../../components/Button/IconButton';
import CommentsHeader from '../CommentsHeader';
import CommentsPane from '../CommentsPane';
import { Spinner } from '../../components/Spinner';
import { useScrollTo } from './useScrollTo';

const DEFAULT_WIDTH = 500;

export default function CommentsFlyout(props) {
  const {
    showComments,
    showCommentPane,
    isHeaderVisible,
    isFocusMode,
    isLoading,
  } = props;

  const [proofCtrl] = useState(props.getProofCtrl());
  const getProofCtrl = useCallback(() => props.getProofCtrl(), []);

  const virtualListRef = useRef();
  const [deferredScroll, setDeferredScroll] = useScrollTo({
    proofCtrl,
    virtualListRef,
  });

  const closeCommentPane = useCallback(() => showCommentPane(false), []);
  const toggleCommentPane = () => showCommentPane(!showComments);

  const { isSmallScreen } = useScreenSize();
  const [commentPaneWidth] = useLocalStorage('pageproof.app.commentPaneWidth', DEFAULT_WIDTH);
  const [delayUnmount, setDelayUnmount] = useState(false);

  const widthRef = useRef(+commentPaneWidth);
  const flyoutRef = useRef();

  const setWidth = useCallback((width) => {
    widthRef.current = width;
    flyoutRef.current.style.width = widthRef.current + 'px';
  }, []);

  useEffect(() => {
    flyoutRef.current.style.width = widthRef.current + 'px';
  }, []);

  useEffect(() => {
    if (showComments) {
      setDelayUnmount(true);
    } else {
      setTimeout(() => {
        setDelayUnmount(false);
      }, 200);
    }
  }, [showComments]);

  return (
    <div
      ref={flyoutRef}
      className={classnames(css.CommentsFlyout, {
        // Handle weird behavior where the header hover adjusts the screen height in focus mode
        [css['CommentsFlyout--visible-header']]: !isFocusMode && !isSmallScreen && isHeaderVisible,
        [css['CommentsFlyout--focus-mode']]: isFocusMode,
        [css['CommentsFlyout--open']]: showComments,
      })}
    >
      {isSmallScreen && (
        <div>
          <h3 className={css.CommentsFlyout__Header}>
            <Translation
              value="comments.heading.proof-comments"
              naked
            />
          </h3>
          <IconButton
            onClick={closeCommentPane}
            className={css.CommentsFlyout__Close_Button}
            src="img/interface/menu-close-icon.svg"
          />
        </div>
      )}

      {(showComments || delayUnmount) &&
        <Fragment>
          {isLoading
            ? (
              <Spinner
                center
                color="#fff"
              />
            )
            : (
              <Fragment>
                {props.commentCounts.commentCount > 1 && <CommentsHeader {...getCommentsHeaderProps(props, getProofCtrl)} />}
                <div className={classnames(css.CommentsFlyout__Comments_Pane, {
                  [css['CommentsFlyout__Comments_Pane--with-header']]: props.commentCounts.commentCount > 1,
                })}
                >
                  <CommentsPane
                    getProofCtrl={proofCtrl}
                    spacing={commentPaneWidth <= 420 ? 'condensed' : 'spacious'}
                    deferredScroll={deferredScroll}
                    setDeferredScroll={setDeferredScroll}
                    virtualListRef={virtualListRef}
                  />
                </div>
              </Fragment>
            )
          }
          <ResizeHandle
            width={widthRef.current}
            setWidth={setWidth}
            isDarkMode={props.isDarkMode}
            whenResized={props.whenResized}
          />
        </Fragment>
      }
      <FlyoutHandle
        showComments={showComments}
        toggleCommentPane={toggleCommentPane}
        commentCount={props.commentCounts.commentCount}
        privateCount={props.commentCounts.privateCount}
        navigateComments={props.navigateComments}
        showUpArrow={props.showUpArrow}
        showDownArrow={props.showDownArrow}
      />
    </div>
  );
}

CommentsFlyout.displayName = 'CommentsFlyout';

function getCommentsHeaderProps(props, getProofCtrl) {
  return {
    getProofCtrl,
    commentCount: props.commentCounts.commentCount,
    repliesCount: props.commentCounts.repliesCount,
    privateCount: props.commentCounts.privateCount,
    privateRepliesCount: props.commentCounts.privateRepliesCount,
    unmarkedCount: props.commentCounts.unmarkedCount,
    todoCount: props.commentCounts.todoCount,
    doneCount: props.commentCounts.doneCount,
    commentLabelCount: props.commentCounts.commentLabelCount,
    agreeCount: props.commentCounts.agreeCount,
    notAgreeCount: props.commentCounts.notAgreeCount,
    attachmentCount: props.commentCounts.attachmentCount,
    importFileNameCounts: props.commentCounts.importFileNameCounts,
    deviceCategoryCounts: props.commentCounts.deviceCategoryCounts,
    webPageCounts: props.commentCounts.webPageCounts,
    mentionedUsers: props.mentionedUsers,
    commentByUsers: props.commentByUsers,
    commentedPages: props.commentedPages,
    filterName: props.filters.filterName,
    filteredMentionId: props.filters.filteredMentionId,
    filteredLabelId: props.filters.filteredLabelId,
    filteredPage: props.filters.filteredPage,
    filteredUserId: props.filters.filteredUserId,
    filteredSearchText: props.filters.filteredSearchText,
    filteredImportFileName: props.filters.filteredImportFileName,
    filteredDeviceCategory: props.filters.filteredDeviceCategory,
    filteredWebPage: props.filters.filteredWebPage,
    proofFileCategory: props.proofFileCategory,
    commentOrder: props.commentOrder,
    onChangeCommentOrder: props.onChangeCommentOrder,
    isVideoOrAudio: props.isVideoOrAudio,
    isCompareMode: false, // compare mode is not yet implemented
  };
}
